<template>
  <el-container>
    <el-aside width="713px">
      <el-container>
        <el-header height="85px">
          <el-form :inline="true">
            <el-form-item>
              <el-select v-model="queryParam.TimeType">
                <el-option value="CheckTime" label="入库时间"></el-option>
                <el-option value="CreateTime" label="退货时间"></el-option>
              </el-select>
              <el-date-picker v-model="queryParam.dateRange" type="daterange" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd" class="date-range" range-separator="至" :editable="false"
                :clearable="true"></el-date-picker>
            </el-form-item>
            <el-form-item label="供应商：" style="float: right">
              <el-input v-model="queryParam.SupplierName" style="width: 210px" placeholder="请输入供应商"></el-input>
            </el-form-item>
            <el-form-item label="入库状态：">
              <el-radio-group v-model="queryParam.Status">
                <el-radio :label="-1">全部</el-radio>
                <el-radio :label="0">待入库</el-radio>
                <el-radio :label="1">已入库</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="float: right">
              <el-button type="primary" @click="handleQuery()">查询</el-button>
              <el-button type="default" @click="reset()">重置</el-button>
              <el-button type="primary" plain @click="handleExport(0)" :loading="batchExportLoading">导出Excel</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showLastEmptyColumn="false" :showPagination="true"
            :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @handleRowClick="handleRowClick"
            :resizable="false">
            <template slot="CheckCount" slot-scope="{ params }">
              {{ getCheckCount(params) }}
            </template>
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main class="detail">
      <el-container>
        <el-header height="35px">
          <span class="span-name">
            退货日期：{{ detail.row == null ? '' : detail.row.RefundDate }}
          </span>
          <span class="span-total" v-if="$store.state.routes.systemSetting.MultiWarehouse && detail.row != null">
            仓库：
            <template v-if="checkRight('inStock')">
              <warehouse-select :value.sync="detail.row.WID" select_style="width:150px;"
                :set_default="true"></warehouse-select>
            </template>
            <template v-else>
              {{ detail.row.WName ? detail.row.WName : '默认仓库' }}
            </template>
          </span>
          <span class="span-total">退货合计：{{ detail.Num }}件</span>
          <span class="span-spec-count">货号合计：{{ detail.totalItemNum }}个</span>
          <span class="span-spec-count">入库合计：{{ detail.CheckNum }}个</span>
        </el-header>
        <el-main>
          <grid-table ref="detailTable" class="detail-table" :showIndexColumn="false" :showCheckboxColumn="false"
            :showPagination="false" :showLastEmptyColumn="false" :tableConfig="detailConfig"
            @handleCheckDisable="handleCheckDisable" @handleInputChange="handleInputChange">
            <template slot="CheckNumHeader" slot-scope="{}">
              <span
                :style="{ 'line-height': detail.row != null && detail.row.CheckStatus == '已入库' ? '32px' : '' }">入库数量</span>
              <el-button class="table-button" type="primary" @click="allInStock"
                v-if="detail.row != null && detail.row.CheckStatus != '已入库'" size="mini">全部入库</el-button>
            </template>
            <template slot="Num" slot-scope="{ params }">

              <div v-if="calcEmptySpec(params.row.ProductID) > 0" @click="handleSetEmptySpec(params.row)"
                class="div-wait-setspec" title="点击去分配">
                待分配 {{ calcEmptySpec(params.row.ProductID) }} 件
              </div>
              <div v-else>
                <div
                  v-if="calcEmptySpec(params.row.ProductID) <= 0 && typeof (detail.EmptySpecs[params.row.ProductID]) != 'undefined' && detail.EmptySpecs[params.row.ProductID].EmptyCount > 0"
                  class="div-setspeced">
                  已分配待入库 {{ params.row.Num }} 件
                </div>
                <div v-else>
                  {{ params.row.Num }}
                </div>
              </div>
            </template>
          </grid-table>
        </el-main>
        <el-footer height="40px">
          <div>
            <el-button type="success" @click="handleSaveData('save')" :loading="saveLoading"
              v-if="checkRight('save')">修改保存</el-button>
            <el-button type="primary" @click="handleSaveData('inStock')" :loading="inStockLoading"
              v-if="checkRight('inStock')">确认入库</el-button>
          </div>
          <div>
            <el-button type="default" @click="handleExport(1)" v-if="checkRight('exportExcel')"
              :loading="exportLoading">导出Excel</el-button>
            <el-button type="default" @click="handleCopy">复制报单</el-button>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <add-goods ref="addGoodsDlg" @handleSave="handleAddGoods"></add-goods>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import dayjs from "dayjs";
import clipboard from "@/utils/clipboard";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import AddGoods from "@/views/purchase/components/AddGoods.vue";
export default {
  name: "refundedIndex",
  components: { WarehouseSelect, AddGoods },
  data() {
    return {
      routeParam: {},
      tableConfig: {
        url: "/api/stallRefund/getGroupedList",
        rowKey: "RefundDate",
        columns: [
          {
            label: "退货日期",
            prop: "RefundDate",
            type: "text",
            width: "100px",
          },
          {
            label: '仓库',
            prop: 'WName',
            type: 'text',
            isHidden: !this.$store.state.routes.systemSetting.MultiWarehouse,
            width: "100px",
          },
          {
            label: "退货人数",
            prop: "PurchaseNum",
            type: "text",
          },
          {
            label: "退货总数",
            prop: "TotalCount",
            type: "text",
          },
          {
            label: "入库状态",
            prop: "CheckStatus",
            type: "text",
            width: "85px",
          },
          {
            label: "入库日期",
            prop: "CheckDate",
            type: "text-datetime",
            dateFormat: "yyyy-MM-dd",
            width: "100px",
          },
          {
            label: "入库数量",
            prop: "CheckCount",
            props: ['TotalCount', 'CheckCount', 'CheckStatus'],
            type: 'custom-operation',
          },
        ],
      },
      queryParam: {
        TimeType: "CheckTime",
        SupplierName: "",
        Status: -1,
        dateRange: [],
      },
      detail: {
        isUpdate: false,//数据是否有改动
        loading: false,
        row: null,
        rowDetails: [],
        totalItemNum: 0,
        Num: 0,
        CheckNum: 0,
      },
      detailConfig: {
        url: "/api/stallRefund/getGroupedDetails",
        columns: [
          {
            label: "货号",
            prop: "ItemNo",
            type: "text",
            filter: true
          },
          {
            label: "颜色",
            prop: "Color",
            type: "text",
            filter: true
          },
          {
            label: "尺码",
            prop: "Size",
            type: "text",
            filter: true
          },
          {
            label: "退货数量",
            prop: "Num",
            type: "custom-operation",
            width: "180"
          },
          {
            label: "入库数量",
            prop: "CheckNum",
            headerCustom: true,
            type: "input-int",
            width: "180",
            method: 'handleInputChange',
            checkDisableMethod: 'handleCheckDisable'
          },
          {
            label: "供应商",
            prop: "SupplierName",
            type: "text",
            width: "180"
          },
        ],
      },
      saveLoading: false,
      inStockLoading: false,
      batchExportLoading: false,
      exportLoading: false,
      selectRows: [],
    };
  },
  mounted() {
    var that = this;
    that.$nextTick(async () => {
      that.handleQuery();
    });
  },
  methods: {
    getEmptySpecTotal(productId) {
      var that = this;
      if (typeof (that.detail.EmptySpecs[productId]) != 'undefined') {
        return that.detail.EmptySpecs[productId].EmptyCount + that.detail.EmptySpecs[productId].OtherCount;
      }
      return 0;
    },
    calcEmptySpec(productId) {
      var that = this;
      var count = 0;
      var total = that.getEmptySpecTotal(productId);
      that.$refs.detailTable.tableData.forEach(i => {
        if (i.ProductID == productId) {
          if (i.SpecID <= 0) {
            count += i.CheckNum;
          } else {
            if (i.Num > 0) {
              if (i.CheckNum >= i.Num) {
                count += i.CheckNum;
              } else {
                count += i.Num;
              }
            } else {
              count += i.CheckNum;
            }
          }
        }
      });
      return total - count;
    },
    handleAddGoods(data) {
      var that = this;
      var list = that.$refs.detailTable.tableData;
      var emptySpecCount = that.calcEmptySpec(data.ProductID);
      if (emptySpecCount <= 0) {
        that.$baseMessage('货号' + data.ItemNo + '没有待分配的商品', "error");
        return;
      }
      for (let color in data.Colors) {
        for (let i = 0; i < data.Colors[color].Details.length; i++) {
          var hasSpec = list.some(x => x.ProductID == data.ProductID && x.SpecID == data.Colors[color].Details[i].SpecID);
          if (!hasSpec && data.Colors[color].Details[i].Num > 0) {
            list.push({
              ProductID: data.Colors[color].Details[i].ProductID,
              ItemNo: data.ItemNo,
              SpecID: data.Colors[color].Details[i].SpecID,
              SpecName: color + '-' + data.Colors[color].Details[i].Size,
              Color: color,
              Size: data.Colors[color].Details[i].Size,
              Num: data.Colors[color].Details[i].Num,
              CheckNum: data.Colors[color].Details[i].Num,
              CheckStatus: 0,
              _New: true,
            });
          }
        }
      }
      list = list.filter(x => x.ProductID != data.ProductID || (x.ProductID == data.ProductID && x.Color != ''))
      list = list.sort(function (a, b) {
        if (a.ProductID !== b.ProductID) return a.ProductID < b.ProductID ? -1 : 1
        else if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1
        else if (a.Size !== b.Size) return a.Size < b.Size ? -1 : 1
      });
      that.$refs.detailTable.setData(list);
    },
    handleSetEmptySpec(row) {
      let sizeMaxNum = this.calcEmptySpec(row.ProductID)
      this.$refs.addGoodsDlg.init(false, '分配', row.ItemNo, undefined, true, sizeMaxNum);
    },
    allInStock() {
      var that = this;
      that.$refs.detailTable.tableData.forEach((detail, index) => {
        if (detail.Num > detail.CheckNum && detail.SpecID > 0) {
          that.handleInputChange(index, detail.Num, 'CheckNum');
        }
      });
    },
    handleInputChange(rowIndex, value, prop) {
      let row = this.$refs.detailTable.tableData[rowIndex];
      if (row[prop] != value) {
        row[prop] = value;
      }
      let changNum = 0;
      if (row[prop] != row['Old' + prop]) {
        changNum = row[prop] - row['Old' + prop];
        row['Old' + prop] = row[prop];
        this.detail.isUpdate = true;
      }
      if (changNum == 0) {
        return;
      }
      this.detail[prop] += changNum;
    },
    handleCheckDisable(row, callback) {
      if (this.detail.row == null) {
        callback(true);
      } else {
        if (this.detail.row.CheckStatus != '已入库') {
          if (row.SpecID <= 0) {
            callback(true);
          } else {
            callback(false);
          }
        }
        else {
          callback(true);
        }
      }
    },
    getCheckCount(params) {
      if (params.row['CheckStatus'] == '待入库') {
        return '';
      } else {
        return params.row['CheckCount'];
      }
    },
    checkRight(type, row, sizeDetail) {
      var that = this;
      switch (type) {
        case "inStock":
        case "delete":
          return that.detail.row != null && that.detail.row.CheckStatus != '已入库';
        case "save":
          return false;
        //return that.detail.row != null && that.detail.row.CheckStatus != '已入库';
        case "exportExcel":
          return that.detail.row != null;
      }
    },
    reset() {
      this.queryParam.dateRange = [];
      this.queryParam.SupplierName = "";
      this.queryParam.Status = -1;
      this.handleQuery();
    },
    async handleSaveData(type) {
      var that = this;
      //验证数据
      var param = {
        RefundDate: that.detail.row == null ? '' : that.detail.row.RefundDate,
        RefundIDs: that.detail.row.RefundIDs,
        Type: type,
        WID: that.detail.row.WID,
        Details: []
      };
      var list = [...that.$refs.detailTable.tableData];
      if (list.length <= 0) {
        that.$baseMessage(`退货商品不能为空`, "error");
        return;
      }
      var errorList = [];
      var totalCheckNum = 0;
      var totalNum = 0;
      var emptySpecList = that.detail.EmptySpecs;
      for (let key in emptySpecList) {
        totalNum += emptySpecList[key].EmptyCount + emptySpecList[key].OtherCount;
      }
      var statInfo = {};
      list.forEach(y => {
        if (y.CheckStatus == 0) {
          if (typeof (statInfo[y.ItemNo]) == 'undefined') {
            statInfo[y.ItemNo] = {
              CheckNum: 0,
              Total: emptySpecList[y.ProductID].EmptyCount + emptySpecList[y.ProductID].OtherCount,
            };
          }
          statInfo[y.ItemNo].CheckNum += y.CheckNum;
          totalCheckNum += y.CheckNum;
          if (!(typeof (y._New) != 'undefined' && y._New && y.Num == 0 && y.CheckNum == 0)) {
            param.Details.push({
              ProductID: y.ProductID,
              SpecID: y.SpecID,
              CheckNum: y.CheckNum,
            });
          }
        }
      });
      var hasNoSet = param.Details.some(x => x.SpecID == 0);
      if (hasNoSet) {
        that.$baseMessage('存在未分配的商品，请分配到具体规格后入库', "error");
        return;
      }
      for (let key in statInfo) {
        if (statInfo[key].CheckNum != statInfo[key].Total) {
          errorList.push('货号[' + key + ']入库数量与实际待入库数量不符');
        }
      }
      if (errorList.length > 0) {
        that.$baseMessage(errorList.join('<br/>'), "error");
        return;
      }
      //提交
      if (param.Type == 'inStock') {
        that.$confirm('当前退货单应入库 ' + totalNum + ' 件，实际入库 ' + totalCheckNum + ' 件，确认入库?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          that.handleSubmitData(param);
        }).catch(() => {
          //取消          
        });
      } else {
        that.handleSubmitData(param);
      }
    },
    async handleSubmitData(param) {
      var that = this;
      if (param.Type == 'save') {
        that.saveLoading = true;
      } else {
        that.inStockLoading = true;
      }
      const result = await submit(`/api/stallRefund/save`, param).catch((error) => {
        if (param.Type == 'save') {
          that.saveLoading = false;
        } else {
          that.inStockLoading = false;
        }
      });
      if (param.Type == 'save') {
        that.saveLoading = false;
      } else {
        that.inStockLoading = false;
      }
      if (result.success) {
        that.$message({
          type: "success",
          message: "操作成功!",
        });
        that.handleQuery(result.data, param.RefundDate != '');
      }
    },
    removeEditData() {
      this.detail.row = null;
      this.detail.rowDetails = [];
      this.detailShowType = -1;
      this.detail.isUpdate = false;
      this.detail.Num = 0;
      this.detail.CheckNum = 0;
    },
    handleQuery(refundDate, isCurrentChange = false) {
      var that = this;
      that.$refs.gridTable.searchParam = { ...that.queryParam };
      if (that.queryParam.dateRange && that.queryParam.dateRange.length > 0) {
        that.$refs.gridTable.searchParam.TimeStart = that.queryParam.dateRange[0];
        that.$refs.gridTable.searchParam.TimeEnd = that.queryParam.dateRange[1];
      } else {
        that.$refs.gridTable.searchParam.TimeStart = '';
        that.$refs.gridTable.searchParam.TimeEnd = '';
      }
      delete that.$refs.gridTable.searchParam.dateRange;
      if (refundDate && refundDate != '') {
        that.$refs.gridTable.fetchData(isCurrentChange, function (tableData) {
          var rows = tableData.filter(x => x.RefundDate == refundDate);
          if (rows.length > 0) {
            that.$nextTick(() => {
              that.rowClick(rows[0]);
            });
          }
        });
      } else {
        that.$refs.gridTable.fetchData();
        that.removeEditData();
      }
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    handleRowClick(row) {
      var that = this;
      if (that.detail.isUpdate) {
        that.$confirm(`当前采购单存在变动尚未保存，点击确定将丢失变动过的数据，是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          that.rowClick(row);
        }).catch(() => {
          //取消          
        });
      } else {
        that.rowClick(row);
      }
    },
    async rowClick(row) {
      var that = this;
      that.$refs.gridTable.$refs.gridTable.clearSelection();
      that.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      that.$refs.detailTable.loading = true;
      //加载数据
      that.detailShowType = -1;
      that.detail.EmptySpecs = {};
      that.detail.row = null;
      that.detail.rowDetails = [];
      const result = await submit("/api/stallRefund/getGroupedDetails", { RefundDate: row.RefundDate });
      if (result.success) {
        that.detail.row = JSON.parse(JSON.stringify(result.data));
        that.detail.rowDetails = JSON.parse(JSON.stringify(that.detail.row.Details));
      }
      let itemNos = {};
      for (let i = 0; i < that.detail.rowDetails.length; i++) {
        let x = that.detail.rowDetails[i];

        itemNos[x['ItemNo']] = 1;

        let emptyCount = x.SpecID == 0 ? x.Num : 0;
        let otherCount = x.SpecID != 0 ? x.Num : 0;
        if (typeof (that.detail.EmptySpecs[x.ProductID]) == 'undefined') {
          that.detail.EmptySpecs[x.ProductID] = {
            EmptyCount: emptyCount,
            OtherCount: otherCount,
          };
        } else {
          that.detail.EmptySpecs[x.ProductID].EmptyCount += emptyCount;
          that.detail.EmptySpecs[x.ProductID].OtherCount += otherCount;
        }
      }
      that.detail.Num = result.data.TotalCount;
      that.detail.CheckNum = result.data.CheckCount;

      that.detail.totalItemNum = Object.keys(itemNos).length;
      that.$refs.detailTable.setData(that.detail.rowDetails);
      that.detail.isUpdate = false;
      that.$refs.detailTable.loading = false;
    },
    async handleExport(type) {//0选中的；1编辑的
      var that = this;
      var dates = [];
      if (type == 0) {
        if (that.selectRows.length <= 0) {
          that.$baseMessage(`请选择要导出的数据`, "error");
          return;
        }
        that.selectRows.forEach(x => {
          dates.push(x.RefundDate);
        });
      } else {
        if (that.detail.row != null) {
          dates.push(that.detail.row.RefundDate);
        }
      }
      if (dates.length <= 0) {
        that.$baseMessage(`请选择要导出的数据`, "error");
        return;
      }
      if (type == 0) {
        that.batchExportLoading = true;
      } else {
        that.exportLoading = true;
      }
      for (let i = 0; i < dates.length; i++) {
        const today = dayjs(dates[i]).format('YYYYMMDD');
        const { data } = await exportFile('/api/stallRefund/export', {
          RefundDate: dates[i],
        });
        const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = '销售退货单_' + today + '.xls';
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      if (type == 0) {
        that.batchExportLoading = false;
      } else {
        that.exportLoading = false;
      }
    },
    handleCopy(e) {
      var that = this;
      var list = [...that.$refs.detailTable.tableData];
      if (list.length <= 0) {
        that.$baseMessage(`没有可复制的商品`, "error");
        return;
      }
      var arr = {};
      list.forEach(y => {
        if (typeof (arr[y.ItemNo]) == 'undefined') {
          arr[y.ItemNo] = [];
        }
        var colorItem = '';
        if (y.CheckNum > 0) {
          colorItem += ' ' + y.Size + '*' + y.CheckNum;
        }
        if (colorItem != '') {
          arr[y.ItemNo].push(y.Color + colorItem);
        }
      });
      var text = '';
      for (var key in arr) {
        if (arr[key].length > 0) {
          text += key + '' + arr[key].join('') + '\r\n';
        }
      }
      if (text == '') {
        that.$baseMessage(`没有可复制的商品，请商品数量大于0`, "error");
      } else {
        clipboard(text, e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-aside {
    .el-select {
      width: 100px;
      margin-right: 5px;
    }

    .date-range {
      width: 210px;

      .el-range-input {
        width: 70px;
      }
    }

    .el-radio {
      margin-right: 10px;
    }

    .el-form-item__label {
      padding-right: 0;
    }
  }

  .div-setspeced {
    color: green;
    font-size: 14px;
  }

  .detail {
    .el-header {
      color: #000000;
      margin-bottom: 10px;
      height: 32px;
      line-height: 32px;
      vertical-align: middle;

      .span-name {
        font-weight: bold;
        font-size: 20px;
      }

      .span-total,
      .span-spec-count {
        margin-left: 20px;
        font-size: 16px;
      }

      .span-batch-num {
        float: right;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        margin-top: 15px;
      }

      .span-delete:hover,
      .div-wait-setspec:hover {
        text-decoration: underline;
      }

      .div-wait-setspec {
        color: red;
        font-size: 14px;
        cursor: pointer;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }
    }

    .detail-table {

      .el-icon-delete {
        color: #ff0000;
        cursor: pointer;
      }

      td .el-input-number {
        .el-input {
          width: 150px;
          font-size: 20px;

          .el-input__inner {
            text-align: center;
          }
        }
      }

      .batch-num {
        float: right;
        width: 60px;
      }

      .table-button {
        float: right;
        width: 60px;
        padding: 5px;

        .el-input__inner {
          padding: 0;
        }
      }
    }

    .el-footer {
      .el-button {
        height: 30px;
      }

      &>div {
        display: inline-block;
        width: 50%;
        margin-top: 5px;

        &:last-child {
          text-align: right;

          .div-upload,
          .el-button {
            margin-left: 5px;
          }
        }
      }
    }

  }

  .page-status {
    position: fixed;
    top: 100px;
    right: -42px;
    z-index: 10000;

    &:hover {
      right: 0;
    }

    div {
      border: 1px solid #409eff;
      border-radius: 20px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 3px 3px 3px 5px;
      cursor: pointer;
      background: #fff;

      height: 25px;
      line-height: 25px;
      font-size: 16px;
      width: 60px;
      padding-left: 15px;
      font-weight: bold;
      color: #409eff;
      cursor: pointer;

      &.is-active {
        background: #dbedff;
      }
    }

    div:not(:first-child) {
      border-top: none;
    }

    div.on {
      background-color: #409eff;
      color: #ffffff;
    }

  }
}
</style>
